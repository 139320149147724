import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { unsubscribe } from "../../../utils/redux/actions/subscription.action";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  PWA_ROUTES,
} from "../../../constants/appConstants";

const StyledModalButtons = styled(motion.div)`
  width: 100%;
  padding-top: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #fbc800;

  .btn {
    font-family: MTNBrighterSansRegular;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    padding: 16px;

    display: flex;
    align-items: center;
    text-align: center;
  }
`;

const StyledModalButtonsInteractive = styled(motion.div)`
  width: 100%;
  padding-top: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fbc800;

  .btn {
    font-family: MTNBrighterSansRegular;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    padding: 16px;

    display: flex;
    align-items: center;
    text-align: center;
  }
`;

const ModalButtons = ({
  onClose,
  okButtonText,
  confirmIn,
  primaryActionButtonText,
  onPrimaryAction,
  position,
  clickOn,
}) => {
  const dispatch = useDispatch();
  const { network, subscriptions } = useSelector((state) => state);

  const navigate = useNavigate();
  const { language } = useSelector((state) => state);

  useEffect(() => {
console.log("position",position);
  }, []);

  const confirm = () => {
    // onClose();
    dispatch(
      unsubscribe(network.call, subscriptions?.selectedItem?.billingType),
    );
  };




  return (

      <div>
        <StyledModalButtonsInteractive>
          {
            position === 1 && 
            <>
            <span onClick={() => onClose()} className="btn">
            {language?.chosenPack?.language?.COMPONENTS?.interactive_pager?.first_pager?.dismiss}
            </span>
            <span onClick={() => clickOn(2)} className="btn">
            {language?.chosenPack?.language?.COMPONENTS?.interactive_pager?.first_pager?.continue}
            </span>
            </>
          }

          {
            position === 2 && 
            <>
            <span onClick={() => clickOn(1)} className="btn">
            {language?.chosenPack?.language?.COMPONENTS?.interactive_pager?.second_pager?.previous}
            </span>
            <span onClick={() => clickOn(3)} className="btn">
            {language?.chosenPack?.language?.COMPONENTS?.interactive_pager?.second_pager?.next}
            </span>
            </>
          }

          {
            position === 3 && 
            <>
            <span onClick={() => clickOn(2)}  className="btn">
            {language?.chosenPack?.language?.COMPONENTS?.interactive_pager?.third_pager?.previous}
            </span>
            <span onClick={() => navigate(PWA_ROUTES.MANAGE)} className="btn">
            {language?.chosenPack?.language?.COMPONENTS?.interactive_pager?.third_pager?.next}
            </span>
            </>
          }

          {
            position === 4 && 
            <>
            <span onClick={() => navigate(PWA_ROUTES.HOME)} className="btn">
            {language?.chosenPack?.language?.COMPONENTS?.interactive_pager?.fourth_pager?.previous}
            </span>
            <span onClick={() =>  navigate(PWA_ROUTES.HOME, { state: { interactive: "manage"} })} className="btn">
            {language?.chosenPack?.language?.COMPONENTS?.interactive_pager?.fourth_pager?.next}
            </span>
            </>
          }

          {
            position === 5 && 
            <>
            <span onClick={() => navigate(PWA_ROUTES.MANAGE)} className="btn">
            {language?.chosenPack?.language?.COMPONENTS?.interactive_pager?.fifth_pager?.previous}
            </span>
            <span onClick={() => onClose()} className="btn">
            {language?.chosenPack?.language?.COMPONENTS?.interactive_pager?.fifth_pager?.got}
            </span>
            </>
          }
        </StyledModalButtonsInteractive>

{/* <StyledModalButtonsInteractive> */}
          {/* {
            position === 1 ?
            <>
            <span onClick={() => onClose()} className="btn">
            {"Dismiss"}
            </span>
            <span onClick={navigate(PWA_ROUTES.HOME)} className="btn">
              {"Continue"}
            </span>
            </>
            :
            <div></div>
          } */}
          {/* {
            position === 2? 
            <>
            <span onClick={() => onClose()} className="btn">
            {"Previous"}
            </span>
            <span onClick={() =>  navigate(PWA_ROUTES.MANAGE)} className="btn">
              {"Next"}
            </span>
            </>
            :
            <div></div>
          }
          {
            position === 3? 
            <>
            <span onClick={() => onClose()} className="btn">
            {"Previous"}
            </span>
            <span onClick={() =>  navigate(PWA_ROUTES.MANAGE) } className="btn">
              {"Next"}
            </span>
            </>
            :
            <div></div>
          }
          {
            position === 4? 
            <>
            <span onClick={() => onClose()} className="btn">
            {"Previous"}
            </span>
            <span onClick={() =>  navigate(PWA_ROUTES.HOME, { state: { interactive: "manage"} })} className="btn">
              {"Next"}
            </span>
            </>
            :
            <div></div>
          }
            

          {
            position === 5? 
            <>
            <span onClick={() => onClose()} className="btn">
            {"Previous"}
            </span>
            <span onClick={() => onClose()} className="btn">
              {"Got it"}
            </span>
            </>
            :
            <div></div>
          } */}


          {/* {confirmIn && (
            <span
              onClick={() => {
                confirm();
              }}
              className="btn">
              {"confirm"}
            </span>
          )}
          {primaryActionButtonText && (
            <span
              onClick={() => {
                if (onPrimaryAction) onPrimaryAction();
              }}
              className="btn">
              {primaryActionButtonText}
            </span>
          )} */}
        {/* </StyledModalButtonsInteractive> */}

        {/* {
          position !== "" &&
          <StyledModalButtonsInteractive>
          {
            position === 1 ?
            <>
            <span onClick={() => onClose()} className="btn">
            {"Dismiss"}
            </span>
            <span onClick={navigate(PWA_ROUTES.HOME)} className="btn">
              {"Continue"}
            </span>
            </>
            :
            <div></div>
          }
          {
            position === 2? 
            <>
            <span onClick={() => onClose()} className="btn">
            {"Previous"}
            </span>
            <span onClick={() =>  navigate(PWA_ROUTES.MANAGE)} className="btn">
              {"Next"}
            </span>
            </>
            :
            <div></div>
          }
          {
            position === 3? 
            <>
            <span onClick={() => onClose()} className="btn">
            {"Previous"}
            </span>
            <span onClick={() =>  navigate(PWA_ROUTES.MANAGE) } className="btn">
              {"Next"}
            </span>
            </>
            :
            <div></div>
          }
          {
            position === 4? 
            <>
            <span onClick={() => onClose()} className="btn">
            {"Previous"}
            </span>
            <span onClick={() =>  navigate(PWA_ROUTES.HOME, { state: { interactive: "manage"} })} className="btn">
              {"Next"}
            </span>
            </>
            :
            <div></div>
          }
            

          {
            position === 5? 
            <>
            <span onClick={() => onClose()} className="btn">
            {"Previous"}
            </span>
            <span onClick={() => onClose()} className="btn">
              {"Got it"}
            </span>
            </>
            :
            <div></div>
          }


          {confirmIn && (
            <span
              onClick={() => {
                confirm();
              }}
              className="btn">
              {"confirm"}
            </span>
          )}
          {primaryActionButtonText && (
            <span
              onClick={() => {
                if (onPrimaryAction) onPrimaryAction();
              }}
              className="btn">
              {primaryActionButtonText}
            </span>
          )}
        </StyledModalButtonsInteractive>
        } */}

        {
          position === "" &&
          <StyledModalButtons>
          <span onClick={() => onClose()} className="btn">
            {okButtonText ? okButtonText : "cancel"}
          </span>
          {confirmIn && (
            <span
              onClick={() => {
                confirm();
              }}
              className="btn">
              {"confirm"}
            </span>
          )}
          {primaryActionButtonText && (
            <span
              onClick={() => {
                if (onPrimaryAction) onPrimaryAction();
              }}
              className="btn">
              {primaryActionButtonText}
            </span>
          )}
        </StyledModalButtons>
        }
      </div>
    // <StyledModalButtons>
    //   <span onClick={() => onClose()} className="btn">
    //     {okButtonText ? okButtonText : "cancel"}
    //   </span>
    //   {confirmIn && (
    //     <span
    //       onClick={() => {
    //         confirm();
    //       }}
    //       className="btn">
    //       {"confirm"}
    //     </span>
    //   )}
    //   {primaryActionButtonText && (
    //     <span
    //       onClick={() => {
    //         if (onPrimaryAction) onPrimaryAction();
    //       }}
    //       className="btn">
    //       {primaryActionButtonText}
    //     </span>
    //   )}
    // </StyledModalButtons>
  );
};

export default ModalButtons;